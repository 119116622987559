import React from "react"
import { Link } from "gatsby"
import { FaSkull } from "react-icons/fa"
import { Button } from "react-bootstrap"

//Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/navigation/header"
import Footer from "../components/footer"

const NotFoundPage = () => (
  <div>
    <Header />
    <section className="my-5 ">
      <Layout>
        <SEO title="404: Not found" />
        <div className="text-center">
          <FaSkull className="display-2 mb-4" color="#ff8000" />

          <h1 className="display-3">Page Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <Link to="/">
            <Button variant="primary" size="lg">
              Go Back
            </Button>
          </Link>
        </div>
      </Layout>
    </section>

    <Footer />
  </div>
)

export default NotFoundPage
